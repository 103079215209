(function () {
    var SideMenuAnimate = require('./../../_js-common-modules/side-menu-animate');
    var SlimSidebarAnimate = require('./../../_js-common-modules/slim-sidebar-animate');
    var SidebarEntryAnimate = require('./../../_js-common-modules/sidebar-entry-animate');
    var SlimMenuAnimate = require('./../../_js-common-modules/slim-menu-animate');
    var DropdownMenuAnimate = require('./../../_js-common-modules/dropdown-menu-animate');
    var NestedDropdownAnimate = require('./../../_js-common-modules/nested-dropdown-animate');

    var sidebarElement = document.querySelector('.sidebar');
    var sideMenuElement = document.querySelector('.sidebar .sidebar-menu');
    var dropdownMenus = document.querySelectorAll('.dropdown-menu');
    var nestedDropdowns = document.querySelectorAll('.nested-dropdown');

    if (sideMenuElement) {
        var sideMenuAnimate = new SideMenuAnimate();

        sideMenuAnimate.assignParentElement(sideMenuElement);
    }

    if (sidebarElement) {
        var slimSidebarAnimate = new SlimSidebarAnimate();
        var sidebarEntryAnimate = new SidebarEntryAnimate({ duration: 200 });
        var slimMenuAnimate = new SlimMenuAnimate();

        slimSidebarAnimate.assignParentElement(sidebarElement);
        sidebarEntryAnimate.assignParentElement(sidebarElement);
        slimMenuAnimate.assignSidebarElement(sidebarElement);

        sidebarEntryAnimate.executeAnimation()
            .then(function() {
                sidebarElement.classList.add('sidebar--animate-entry-complete');
            });
    }

    if (dropdownMenus && dropdownMenus.length > 0) {
        var dropdownMenuAnimate = new DropdownMenuAnimate();

        dropdownMenuAnimate.initializeWatcher(dropdownMenus);
    }

    if (nestedDropdowns && nestedDropdowns.length > 0) {
        var nestedDropdownAnimate = new NestedDropdownAnimate();

        nestedDropdownAnimate.initialize(nestedDropdowns);
    }
})();